import api from './api';

class DepositsService {
    findDepositsList(clientId, status, dateFrom, dateTo, page, size) {
        return api.get(`/api/v1/deposit/findDepositsList?clientId=${clientId}&status=${status}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}`);
    }
    findDepositsByDayList(clientId, date) {
        return api.get(`/api/v1/deposit/findDepositsByDayList?clientId=${clientId}&date=${date}`);
    }
}
export default new DepositsService();
